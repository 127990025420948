import React from "react";
import { css } from "@emotion/react";

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCameraRetro } from '@fortawesome/pro-light-svg-icons';

library.add(faCameraRetro);

export const DammyImage = (props) => {
  return <div css={css`
    position: relative;
    width: 100%;
    padding-bottom: 100%;
    height: 0;

    background: #EAEDEE;
    border-radius: 12px;

    .icon {
      position: absolute;
      display: block;
      left: 43%;
      top: 43%;
      width: 15%;
      height: 15%;

      color: rgba(0,0,0,.1);
    }
  `} {...props}>
    <FontAwesomeIcon className="icon" icon={["fal", "camera-retro"]} />
  </div>;
};
