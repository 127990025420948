import styled from "@emotion/styled";
import { css } from "@emotion/react";

export const ButtonGroup = styled.div`
  margin: 1rem 0;
  text-align: center;
`;

export const MoreButtonStyle = css`
  display: inline-block;
  margin-top: 1rem;
  padding: .5rem 2rem;
  min-width: 10rem;

  text-align: center;
  font-weight: bold;

  border: 1px solid #4D4F50;
  border-radius: 1.5rem;

  color: #4D4F50;

  &:hover {
    text-decoration: none;

    border-color: #1D1E1F;
    color: #1D1E1F;
  }
`;
